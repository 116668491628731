:root {  

  --green-light: #75B21D;
  --green-medium: #1C6F20;

  --blue-light: #118BD7;
  --blue-medium: #0D6EB3;
  --blue-dark: #08467C;
  --blue-very-dark: #181D27;

  --gray-medium: #535862;

  --white-background: #FAFAFA;
  --gray-background: #F2F2F2;

  --green1: rgb(18, 63, 28);
  --green2: rgb(28, 111, 41);
  --green3: rgb(117, 178, 29);

  --blue1: rgb(21, 31, 59);
  --blue2: rgb(8, 70, 124);
  --blue3: rgb(17, 139, 215);
  --black: rgb(70, 70, 70);

  --grayBackground: rgb(238, 237, 237);
  --whiteBackground: rgb(255, 255, 255);
  
  font-family: 'Montserrat', 'Roboto', 'Oxygen';
  background-color: var(--whiteBackground);
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.MuiInputBase-root{
  border-radius: 20px !important;
}
