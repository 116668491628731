@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', 'Roboto', 'Oxygen';
}

.balao {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  max-width: 100%;
  margin-bottom: 20px;
}

.balao::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top-color: #f0f0f0;
  border-bottom: 0;
  margin-left: -10px;
  top: 100%;
}

.programaStatusPlan1 {
  width: 190px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: rgb(228 226 222);
  font-weight: 600;
  /* border-style: solid;
  border-width: 50px 0 50px 50px;
  border-color: transparent transparent transparent #fff; */
}

.programaStatusPlan2 {
  width: 190px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #1976d2;
  border-style: solid;
  border-width: 50px 0 50px 50px;
  border-color: transparent transparent transparent rgb(228 226 222);
  color: white;
  font-weight: 600;
  box-shadow: 10px 5px 5px #818181;
}

.programaStatusPlan3 {
  width: 230px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: rgb(228 226 222);
  border-style: solid;
  border-width: 50px 0 50px 50px;
  border-color: transparent transparent transparent #1976d2;
  font-weight: 600;
}

.programaStatusThink1 {
  width: 190px;
  height: 100px;
  display: flex;
  color: white;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #1976d2;
  font-weight: 600;
  box-shadow: 10px 5px 5px #818181;
}

.programaStatusThink2 {
  width: 190px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: rgb(228 226 222);
  border-style: solid;
  border-width: 50px 0 50px 50px;
  border-color: transparent transparent transparent #1976d2;
  font-weight: 600;
}

.programaStatusThink3 {
  width: 230px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: rgb(228 226 222);
  font-weight: 600;
}

.programaStatusGo1 {
  width: 190px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: rgb(228 226 222);
  font-weight: 600;
}

.programaStatusGo2 {
  width: 190px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: rgb(228 226 222);
  font-weight: 600;
}

.programaStatusGo3 {
  width: 230px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #1976d2;
  border-style: solid;
  border-width: 50px 0 50px 50px;
  border-color: transparent transparent transparent rgb(228 226 222);
  color: white;
  font-weight: 600;
  box-shadow: 10px 5px 5px #818181;
}